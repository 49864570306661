import axios, { AxiosError } from "axios";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import { User } from "@/types/users";
import { StatusCodes } from "http-status-codes";
import { authStore, uiStore } from "@/utils/store-accessor";
import { userApi } from "@/api/users";

@Module({ name: "app" })
export default class AppModule extends VuexModule {
  @Action
  async getUser(id: User["id"]) {
    try {
      const response = await userApi.getUser(id);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        await this.checkApiError(error);
      }
    }
  }

  @Action
  async checkApiError(payload: AxiosError) {
    if (payload.response) {
      switch (payload.response.status) {
        case StatusCodes.UNAUTHORIZED:
          await authStore.logOut();
          break;
        default:
          uiStore.addNotification({
            content: `${payload.response.status} ${payload.response.statusText}`,
            color: "error",
          });
      }
    }
  }
}
