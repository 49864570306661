import { VuexModule, Module, Mutation, Action, config } from "vuex-module-decorators";
import { userStore } from "@/store";
import { userApi } from "@/api/users";
import { authApi } from "@/api/authentication";
import router from "@/router";
import { safeResolve } from "@/store/utils";
import { uiStore } from "@/utils/store-accessor";
import { ChangePassword, LoginData, UserRegister } from "@/types/authentication";

config.rawError = true;

@Module({ name: "auth", namespaced: true })
export default class AuthModule extends VuexModule {
  isLoggedIn: boolean | null = null;
  logInError = "";

  @Mutation
  setLoggedIn(payload: boolean) {
    this.isLoggedIn = payload;
  }

  @Mutation
  setLogInError(payload: string) {
    this.logInError = payload;
  }

  @Action
  async removeLogIn() {
    await authApi.logOut();
    this.setLoggedIn(false);
  }

  @Action
  async checkLoggedIn() {
    if (!this.isLoggedIn) {
      const { data, errors } = await safeResolve(userApi.getProfile(), true);

      if (errors) {
        await this.removeLogIn();
      } else if (data) {
        userStore.setProfile(data);
        this.setLoggedIn(true);
      }
    }
  }

  @Action
  async routeLoggedIn() {
    if (["/login", "/"].includes(router.currentRoute.path)) {
      router.push("/main");
    }
  }

  @Action
  async logIn(payload: LoginData): Promise<void> {
    const logInPromise = authApi.logIn(payload);
    const { data, errors } = await safeResolve(logInPromise, true);
    userStore.clearCache();

    if (errors) {
      await this.logOut();
      this.setLogInError("Anmeldung fehlgeschlagen");
    } else if (data) {
      this.setLoggedIn(true);
      await userStore.getProfile();
      await this.routeLoggedIn();
      uiStore.addNotification({
        content: "Anmeldung erfolgreich",
        color: "success",
      });
      this.setLogInError("");
    }
  }

  @Action
  async routeLogOut() {
    if (router.currentRoute.path !== "/login") {
      router.push("/login");
    }
  }

  @Action
  async logOut() {
    userStore.clearCache();
    await this.removeLogIn();
    await this.routeLogOut();
  }

  @Action
  async userLogOut() {
    await this.logOut();
    uiStore.addNotification({
      content: "Abmeldung erfolgreich",
      color: "success",
    });
  }

  @Action
  async resetPassword(payload: { password: string; token: string }) {
    const loadingNotification = {
      content: "Passwort wird zurückgesetzt",
      showProgress: true,
    };
    try {
      uiStore.addNotification(loadingNotification);
      await Promise.all([
        authApi.resetPassword(payload.password),
        await new Promise<void>((resolve, _reject) => setTimeout(() => resolve(), 500)),
      ]);
      uiStore.removeNotification(loadingNotification);
      uiStore.addNotification({
        content: "Passwort erfolgreich zurückgesetzt",
        color: "success",
      });
      await this.logOut();
    } catch (error) {
      uiStore.removeNotification(loadingNotification);
      uiStore.addNotification({
        color: "error",
        content: "Fehler beim Zurücksetzen des Passworts",
      });
    }
  }

  @Action
  async recoverPassword(payload: { username: string }) {
    const loadingNotification = {
      content: "Sending password recovery email",
      showProgress: true,
    };
    try {
      uiStore.addNotification(loadingNotification);
      await Promise.all([
        authApi.passwordRecovery(payload.username),
        await new Promise<void>((resolve, _reject) => setTimeout(() => resolve(), 500)),
      ]);
      uiStore.removeNotification(loadingNotification);
      uiStore.addNotification({
        content: "E-Mail zum Zurücksetzen des Passworts gesendet",
        color: "success",
      });
      await this.logOut();
    } catch (error) {
      uiStore.removeNotification(loadingNotification);
      uiStore.addNotification({
        color: "error",
        content: "Unzulässiger Benutzername",
      });
    }
  }

  @Action
  async changeUserPassword(payload: ChangePassword) {
    const { data, errors } = await safeResolve(authApi.changePassword(payload), true);
    if (errors) {
      return errors;
    } else if (data) {
      uiStore.addNotification({ content: data.detail, color: "success" });
    }
  }

  @Action
  async registerUser(payload: UserRegister) {
    const { data, errors } = await safeResolve(authApi.register(payload), true);

    if (errors) {
      return errors;
    } else if (data) {
      uiStore.addNotification({ content: data.detail, color: "success" });
    }
  }
}
