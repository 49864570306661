import { SiteCreate, SiteDetail } from "@/types/requisitions";

export function omitSiteIfEmpty<T extends { site?: SiteCreate | SiteDetail | null }>(
  data: T,
): T | Omit<T, "site"> {
  const { site, ...rest } = data;

  if (!site) {
    return rest;
  }

  for (const value of Object.values(site)) {
    if (typeof value === "string" && value.trim()) {
      return { site, ...rest };
    }
  }

  return rest;
}
