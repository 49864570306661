import { SafeResource, UnsafeResource, ISODateString } from "@/types/utils";
import { apiUrl } from "@/env";

export const getRefreshToken = () => localStorage.getItem("refresh-token");

export const saveRefreshToken = (token: string) =>
  localStorage.setItem("refresh-token", token);

export const removeRefreshToken = () => localStorage.removeItem("refresh-token");

export const isEAN13 = (code: string) => {
  if (!/^\d{13}$/g.test(code)) return false;

  let checksum = 0;
  const codelist = code.split("");
  const checkdigit = parseInt(codelist.pop() as string, 10);
  codelist.map((value, index) => {
    const digit = parseInt(value, 10);
    if (code.length % 2 === 1) checksum += index % 2 ? digit * 3 : digit;
    else checksum += index % 2 ? digit : digit * 3;
  });

  let check = checksum % 10;
  if (check !== 0) check = 10 - check;
  if (check === checkdigit) return true;
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initProxy = (defaultFactory: () => any = () => "") => {
  return new Proxy(
    {},
    {
      get: function (obj, prop) {
        return prop in obj ? obj[prop] : defaultFactory();
      },
    },
  );
};

export function verifyResource(resource: UnsafeResource): SafeResource {
  const safeHostname = new URL(apiUrl).hostname;
  const url = new URL(resource);
  if (url.hostname !== safeHostname) {
    throw new Error(`Unsafe resource '${resource}'`);
  }
  return url as SafeResource;
}

export function toLocaleString(value: Date | ISODateString) {
  return new Date(value).toLocaleString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
}
