import { searchApi } from "@/api/search";
import { Article } from "@/types/requisitions";
import { SearchParameters } from "@/types/search";
import { Except } from "type-fest";
import { Action, config, Module, VuexModule } from "vuex-module-decorators";
import { safeResolve } from "@/store/utils";

config.rawError = true;

@Module({ name: "search", namespaced: true })
export default class SearchModule extends VuexModule {
  articleCache: { [barcode: string]: Except<Article, "id"> | null } = {};

  @Action
  async searchInventory(
    payload: { params: SearchParameters<"articles"> } = { params: {} },
  ) {
    return safeResolve(searchApi.searchSolr("articles", payload.params));
  }

  @Action
  async search(payload: {
    path: Parameters<typeof searchApi.searchSolr>[0];
    searchParams: Parameters<typeof searchApi.searchSolr>[1];
  }) {
    return safeResolve(searchApi.searchSolr(payload.path, payload.searchParams));
  }
}
