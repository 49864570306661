import axios from "axios";
import { apiUrl } from "@/env";
import { SearchParameters, SearchPath, SearchResponse } from "@/types/search";

const client = axios.create({
  baseURL: `${apiUrl}/search`,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
  withCredentials: true,
});

export const searchApi = {
  async searchSolr<Path extends SearchPath>(
    path: Path,
    searchParams?: SearchParameters<Path>,
  ) {
    const params: Parameters<typeof client.get> = [`/${path}/`];
    if (searchParams) {
      params.push({ params: searchParams });
    }
    return await client.get<SearchResponse<Path>>(...params);
  },
};
