import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { companyStore } from "@/store";
import { RequisitionDetail } from "@/types/requisitions";
import { CompanyDetail, CompanyLogoDetail, WarehouseDetail } from "@/types/companies";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

type Node = { id: string };
type PDF = {
  download: (fileName: string) => void;
  getBase64: (cb: (data: string) => void) => void;
  getBlob: (cb: (blob: Blob) => void) => void;
};

function makeHeader(requisition: RequisitionDetail, currentTime: string) {
  return (currentPage: number, _pageCount: number) => {
    let leftText = "";
    if (currentPage > 1) {
      leftText = `${requisition.customer.customer_number} | ${requisition.requisition_no}`;
    }
    return {
      columns: [
        {
          text: leftText,
          alignment: "left",
          width: "auto",
          style: "pageHeader",
          margin: [4, 2, 0, 0],
        },
        {
          text: "",
          width: "*",
        },
        {
          text: currentTime,
          alignment: "right",
          width: "auto",
          style: "pageHeader",
          margin: [0, 2, 4, 0],
        },
      ],
    };
  };
}

function makeFooter() {
  return (currentPage: number, pageCount: number) => {
    return {
      text: `Seite ${currentPage} von ${pageCount}`,
      alignment: "center",
      margin: [0, 20, 0, 0],
    };
  };
}

function makePageBreakBefore() {
  return (
    currentNode: Node,
    followingNodesOnPage: Node[],
    _nodesOnNextPage: Node[],
    _previousNodesOnPage: Node[],
  ) => {
    const currentNodeIsSignatureStart = currentNode.id === "signatureTextStart";
    const followingNodeIsSignatureEnd = followingNodesOnPage.some(
      (node) => node.id === "signatureTextEnd",
    );
    return currentNodeIsSignatureStart && !followingNodeIsSignatureEnd;
  };
}

function makePositionTableBody(positions: RequisitionDetail["positions"]) {
  return [
    ["Pos.", "Artikel", "Artikelbeschreibung", "Menge", "Einh."],
    ...positions.map((position, index) => {
      return [
        `${index + 1}`,
        `${position.article.artnr}`,
        `${position.article.kurz_txt_de}`,
        `${position.quantity}`,
        `${position.article.mengeinh_s}`,
      ];
    }),
  ];
}

function makeContent({
  logoType,
  logoData,
  requisition,
  signatureTextEnd,
  warehouseText,
}: {
  logoType: CompanyLogoDetail["file_type"];
  logoData: CompanyLogoDetail["image_data"];
  requisition: RequisitionDetail;
  signatureTextEnd: CompanyDetail["signature"];
  warehouseText: string;
}): object[] {
  const logo = {
    image: `data:image/${logoType};base64,${logoData}`,
    width: 200,
    alignment: "center",
    margin: [0, 0, 0, 15],
  };

  const header = {
    style: "tableRequisitionHeader",
    table: {
      headerRows: 0,
      widths: [280, 90, "*"],
      body: [
        [
          `${requisition.customer.name1 ?? ""}`,
          "Kunden-Nr.:",
          `${requisition.customer.customer_number ?? ""}`,
        ],
        [
          `${requisition.customer.name2 ?? ""}`,
          requisition.order_number ? "Auftrags-Nr.:" : "Baustellen-Nr.:",
          requisition.order_number
            ? `${requisition.order_number}`
            : `${requisition.site?.site_number ?? ""}`,
        ],
        [
          `${requisition.customer.name3 ?? ""}`,
          "Beleg-Datum:",
          `${new Date(requisition.created_at).toLocaleString("de-DE")}`,
        ],
        [
          `${requisition.customer.street_address ?? ""}`,
          "Beleg-Nr.:",
          `${requisition.requisition_no ?? ""}`,
        ],
        [
          `${requisition.customer.zip_code ?? ""} ${requisition.customer.city ?? ""}`,
          "Lager:",
          `${warehouseText}`,
        ],
      ],
    },
    layout: "noBorders",
  };

  const heading = {
    text: "ABHOLSCHEIN / LIEFERSCHEIN",
    style: "header",
  };

  const subheading = {
    text: requisition.site?.name1 ? `BV: ${requisition.site.name1}` : "",
    style: "subheader",
  };

  const articles = {
    style: "tableArticles",
    table: {
      widths: [25, 85, "*", 40, 30],
      body: makePositionTableBody(requisition.positions),
    },
    layout: {
      fillColor: (rowIndex: number) => {
        return rowIndex === 0 ? "#CCCCCC" : null;
      },
      hLineWidth: (rowIndex: number) => {
        return rowIndex === 1 ? 1 : 0;
      },
      vLineWidth: (_rowIndex: number) => {
        return 0;
      },
    },
  };

  const signature = [
    {
      id: "signatureTextStart",
      text: "Ware vollständig und im einwandfreien Zustand erhalten.",
      margin: [0, 60, 0, 40],
    },
    {
      alignment: "center",
      columns: [
        { width: 100, text: "-".repeat(20), decoration: "lineThrough" },
        { width: "*", text: "-".repeat(50), decoration: "lineThrough" },
        { width: 200, text: "-".repeat(35), decoration: "lineThrough" },
      ],
    },
    {
      alignment: "center",
      columns: [
        { width: 100, text: "Datum" },
        { width: "*", text: "Name in Druckschrift" },
        { width: 200, text: "Unterschrift" },
      ],
    },
    {
      id: "signatureTextEnd",
      text: signatureTextEnd,
      margin: [0, 20, 0, 0],
    },
  ];

  return [logo, header, heading, subheading, articles, ...signature];
}

export function createRequisitionPdf(
  requisition: RequisitionDetail,
  warehouse: WarehouseDetail,
  logo: CompanyLogoDetail,
) {
  const currentTime = new Date().toLocaleString("de-DE");
  const warehouseText = `${warehouse.city} Lager ${warehouse.warehouse_number}`;
  const signatureTextEnd = warehouse.company.signature;
  const logoType = logo.file_type;
  const logoData = logo.image_data;

  const docDefinition = {
    pageSize: "A4",
    pageMargins: [40, 60, 40, 80],
    // TODO: add requisition number 'Beleg-Nr.: ', starting on second page
    header: makeHeader(requisition, currentTime),
    footer: makeFooter(),
    pageBreakBefore: makePageBreakBefore(),
    content: makeContent({
      logoType,
      logoData,
      requisition,
      signatureTextEnd,
      warehouseText,
    }),
    styles: {
      header: { fontSize: 18, bold: true, margin: [0, 0, 0, 10] },
      subheader: { fontSize: 14, bold: true, margin: [0, 10, 0, 5] },
      tableRequisitionHeader: { margin: [0, 5, 0, 15] },
      tableArticles: { margin: [0, 5, 0, 15], fontSize: 9 },
      tableHeader: { bold: true, fontSize: 12, color: "black" },
      pageHeader: { margin: [0, 5, 5, 0] },
      signature: { margin: [0, 0, 0, 10] },
    },
    defaultStyle: {
      // alignment: 'justify'
    },
  };

  return pdfMake.createPdf(docDefinition) as PDF;
}

async function createPdf(requisition: RequisitionDetail) {
  const warehouse = await companyStore.getWarehouse({ id: requisition.warehouse_id });
  const logo = await companyStore.getCompanyLogo(warehouse.company.id);
  return createRequisitionPdf(requisition, warehouse, logo);
}

export async function createPdfData(requisition: RequisitionDetail) {
  const pdf = await createPdf(requisition);
  const output: string = await new Promise((resolve) => {
    pdf.getBase64((data) => {
      resolve(data);
    });
  });
  return output;
}

export async function downloadPdf(requisition: RequisitionDetail) {
  const pdf = await createPdf(requisition);
  pdf.download(`Materialschein-${requisition.requisition_no}.pdf`);
}
