



















import { Component, Vue } from "vue-property-decorator";
import NotificationsManager from "@/components/NotificationsManager.vue";
import { authStore } from "@/store";

@Component({
  components: {
    NotificationsManager,
  },
})
export default class App extends Vue {
  get loggedIn() {
    return authStore.isLoggedIn;
  }

  async created() {
    await authStore.checkLoggedIn();
  }
}
