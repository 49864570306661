import { appStore, uiStore } from "@/store";
import { ApiError } from "@/types/common";
import { AxiosResponse, AxiosError } from "axios";

export abstract class ErrorHandler {
  abstract handle(message: string): void;
}

export class NotificationErrorHandler extends ErrorHandler {
  handle(message: string) {
    uiStore.addNotification({ content: message, color: "error" });
  }
}

type ApiPromise<T> = Promise<AxiosResponse<T>>;

export function handleApiError(error: ApiError, handler: ErrorHandler) {
  if (error.extra.fields === undefined) {
    handler.handle(error.message);
    return;
  }

  const errorMessages: { [key: string]: string[] } = {};

  for (const [key, value] of Object.entries(error.extra.fields)) {
    if (typeof value === "object") {
      for (const [subKey, message] of Object.entries(value)) {
        errorMessages[`${key}.${subKey}`] = message;
      }
    } else {
      errorMessages[key] = value;
    }
  }
  handler.handle(error.message);
  return errorMessages;
}

export async function safeResolve<T>(apiPromise: ApiPromise<T>): Promise<T>;
export async function safeResolve<T>(
  apiPromise: ApiPromise<T>,
  withErrors: false,
): Promise<T>;
export async function safeResolve<T>(
  apiPromise: ApiPromise<T>,
  withErrors: true,
): Promise<{ data: T; errors: undefined } | { data: undefined; errors: ApiError }>;
export async function safeResolve<T>(apiPromise: ApiPromise<T>, withErrors = false) {
  try {
    const { data } = await apiPromise;
    return withErrors ? { data } : data;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (withErrors && error.response) {
        const { data } = error.response;
        return { errors: data };
      }
      await appStore.checkApiError(error);
      throw new Error(error.message);
    } else {
      throw new Error("Unbekannter Fehler");
    }
  }
}

export function assertNotNull<T>(obj: T) {
  if (obj === null) throw new Error("Null assertion error");
  return obj as Exclude<T, null>;
}
