import axios from "axios";
import { apiUrl } from "@/env";
import {
  ChangePassword,
  LoginData,
  LoginResponse,
  UserRegister,
} from "@/types/authentication";
import { UserDetail } from "@/types/users";

const client = axios.create({
  baseURL: `${apiUrl}/auth`,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
  withCredentials: true,
});

export const authApi = {
  async logIn(payload: LoginData) {
    return client.post<LoginResponse>("/login/", payload);
  },

  async logOut() {
    return client.post<{ detail: string }>("/logout/");
  },

  async register(data: UserRegister) {
    return client.post<{ detail: string; user: UserDetail }>(`/registration/`, data);
  },

  async changePassword(data: ChangePassword) {
    return client.post<{ detail: string }>(`/password/change/`, data);
  },

  async passwordRecovery(email: string) {
    return client.post(`/password/reset/`, { email });
  },

  async resetPassword(password: string) {
    return client.post(`/password/reset/confirm/`, {
      new_password1: password,
      new_password2: password,
    });
  },
};
