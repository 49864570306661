import { Store } from "vuex";
import { getModule } from "vuex-module-decorators";
import AppModule from "@/store/modules/app";
import RequisitionModule from "@/store/modules/requisition";
import UserModule from "@/store/modules/user";
import SearchModule from "@/store/modules/search";
import CompanyModule from "@/store/modules/company";
import AuthModule from "@/store/modules/authentication";
import UiModule from "@/store/modules/ui";

let appStore: AppModule;
let requisitionStore: RequisitionModule;
let userStore: UserModule;
let searchStore: SearchModule;
let companyStore: CompanyModule;
let authStore: AuthModule;
let uiStore: UiModule;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function initializeStores(store: Store<any>): void {
  appStore = getModule(AppModule, store);
  requisitionStore = getModule(RequisitionModule, store);
  userStore = getModule(UserModule, store);
  searchStore = getModule(SearchModule, store);
  companyStore = getModule(CompanyModule, store);
  authStore = getModule(AuthModule, store);
  uiStore = getModule(UiModule, store);
}

export const modules = {
  app: AppModule,
  requisition: RequisitionModule,
  user: UserModule,
  search: SearchModule,
  company: CompanyModule,
  auth: AuthModule,
  ui: UiModule,
};

export {
  initializeStores,
  appStore,
  requisitionStore,
  userStore,
  searchStore,
  companyStore,
  authStore,
  uiStore,
};
