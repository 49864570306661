import axios from "axios";
import { apiUrl } from "@/env";
import {
  ArticleCreate,
  Requisition,
  RequisitionCreate,
  RequisitionDetail,
  RequisitionListConfig,
  RequisitionListItem,
  RequisitionListPaginationConfig,
  RequisitionUpdate,
  RequisitionUpdateConfig,
} from "@/types/requisitions";
import { SafeResource } from "@/types/utils";
import { PaginatedResponse } from "@/types/common";

const client = axios.create({
  baseURL: `${apiUrl}/crud`,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
  withCredentials: true,
});

export const requisitionApi = {
  async getRequisition(id: Requisition["id"]) {
    return client.get<RequisitionDetail>(`/requisitions/${id}/`);
  },

  async createRequisition(data: RequisitionCreate) {
    return client.post<RequisitionDetail>("/requisitions/create/", data);
  },

  async updateRequisition(id: number, data: RequisitionUpdate) {
    return client.put<"">(`/requisitions/${id}/update/`, data);
  },

  async listRequisitions(options: RequisitionListPaginationConfig) {
    const status =
      typeof options.status === undefined
        ? undefined
        : `${options.status}` || undefined;
    return client.get<PaginatedResponse<RequisitionListItem>>("/requisitions/", {
      params: { ...options, status },
    });
  },

  async getListConfig() {
    return client.get<RequisitionListConfig>("/requisitions/get-list-config/");
  },

  async getUpdateConfig() {
    return client.get<RequisitionUpdateConfig>("/requisitions/get-update-config/");
  },

  async performAction(url: SafeResource, data?: object) {
    return client.post<{ status: string }>(url as unknown as string, data);
  },

  async createArticle(data: ArticleCreate) {
    return client.post("/items/", data);
  },
};
