import axios from "axios";
import { apiUrl } from "@/env";
import {
  Group,
  ProfileDetail,
  ProfileUpdate,
  User,
  UserDetail,
  UserListItem,
  UserUpdate,
} from "@/types/users";

const client = axios.create({
  baseURL: `${apiUrl}/crud`,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
  withCredentials: true,
});

export const userApi = {
  async getMe() {
    return client.get<ProfileDetail>("/users/me/");
  },

  async updateMe(data: ProfileUpdate) {
    return client.put<ProfileDetail>("/users/me/update/", data);
  },

  async getProfile() {
    return await client.get<ProfileDetail>("/users/me/");
  },

  async listUsers() {
    return await client.get<UserListItem[]>("/users/");
  },

  async getUser(id: User["id"]) {
    return await client.get<UserDetail>(`/users/${id}/`);
  },

  async deleteUser(id: User["id"]) {
    return await client.delete<{ detail: string }>(`/users/${id}/delete/`);
  },

  async updateUser(id: User["id"], data: UserUpdate) {
    return await client.put<{ detail: string }>(`/users/${id}/update/`, data);
  },

  async listGroups() {
    return await client.get<Group[]>("/groups/");
  },
};
