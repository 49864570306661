import { VuexModule, Module, Action, Mutation, config } from "vuex-module-decorators";
import { uiStore, appStore } from "@/store";
import { StatusCodes } from "http-status-codes";
import { AxiosError } from "axios";
import { verifyResource } from "@/utils";
import { createPdfData } from "@/utils/pdf";
import {
  Article,
  Requisition,
  RequisitionAction,
  RequisitionCreate,
  RequisitionDetail,
  RequisitionListItem,
  RequisitionListPaginationConfig,
  RequisitionUpdate,
} from "@/types/requisitions";
import { safeResolve } from "@/store/utils";
import { requisitionApi } from "@/api/requisitions";
import { omitSiteIfEmpty } from "@/logic/requisitions";

config.rawError = true;

@Module({ namespaced: true, name: "requisition" })
export default class RequisitionModule extends VuexModule {
  requisitions: RequisitionListItem[] = [];
  totalRequisitions = 0;

  articleCache: { [barcode: string]: Article | null } = {};

  get getOneRequisition() {
    return (requisitionNumber: Requisition["requisition_no"]) => {
      const requisition = this.requisitions.find(
        (requisition) => requisition.requisition_no === requisitionNumber,
      );
      if (requisition) return { ...requisition };
    };
  }

  @Mutation
  setRequisitions(payload: RequisitionListItem[]) {
    this.requisitions = payload;
  }

  @Mutation
  setTotalRequisitions(payload: number) {
    this.totalRequisitions = payload;
  }

  @Mutation
  pushRequisition(payload: RequisitionListItem) {
    this.requisitions.push(payload);
  }

  @Action
  async getRequisitions(payload: RequisitionListPaginationConfig) {
    return safeResolve(requisitionApi.listRequisitions(payload));
  }

  @Action
  async getRequisitionListConfig() {
    return safeResolve(requisitionApi.getListConfig());
  }

  @Action
  async getRequisitionUpdateConfig() {
    return safeResolve(requisitionApi.getUpdateConfig());
  }

  @Action
  async getRequisitionById(id: Requisition["id"]) {
    return safeResolve(requisitionApi.getRequisition(id));
  }

  @Action
  async createRequisition(payload: Readonly<RequisitionCreate>) {
    const data = omitSiteIfEmpty(payload);
    return safeResolve(requisitionApi.createRequisition(data), true);
  }

  @Action
  async updateRequisition(payload: { id: number; data: RequisitionUpdate }) {
    const data = omitSiteIfEmpty(payload.data);
    return safeResolve(requisitionApi.updateRequisition(payload.id, data), true);
  }

  @Action
  async performRequisitionAction(payload: {
    action: RequisitionAction;
    requisition: RequisitionDetail;
  }) {
    const params: Parameters<typeof requisitionApi.performAction> = [
      // verify action url
      verifyResource(payload.action.url),
    ];

    // check if we need to generate any attachments client-side
    if (payload.action.required_data.includes("PDF")) {
      const attachment = {
        data: await createPdfData(payload.requisition),
        type: "PDF",
      };
      params.push({ attachment });
    }

    // perform the action in the backend
    try {
      const response = await requisitionApi.performAction(...params);
      uiStore.addNotification({
        content: response.data.status,
        color: "success",
      });
      return response;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === StatusCodes.BAD_REQUEST) {
          return error.response.data;
        }
        await appStore.checkApiError(error);
      }
    }
  }
}
