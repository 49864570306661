import { Action, config, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AppNotification, FloatingActionButton } from "@/types/ui";

config.rawError = true;

@Module({ name: "ui", namespaced: true })
export default class UiModule extends VuexModule {
  dashboardMiniDrawer = false;
  dashboardShowDrawer = true;
  notifications: AppNotification[] = [];
  activeFab: FloatingActionButton | null = null;

  get firstNotification() {
    return this.notifications.length > 0 && this.notifications[0];
  }

  @Mutation
  setActiveFab(payload: FloatingActionButton | null) {
    this.activeFab = payload;
  }

  @Mutation
  setDashboardMiniDrawer(payload: boolean) {
    this.dashboardMiniDrawer = payload;
  }

  @Mutation
  setDashboardShowDrawer(payload: boolean) {
    this.dashboardShowDrawer = payload;
  }

  @Mutation
  addNotification(payload: AppNotification) {
    this.notifications.push(payload);
  }

  @Mutation
  removeNotification(payload: AppNotification) {
    this.notifications = this.notifications.filter(
      (notification) => notification !== payload,
    );
  }

  @Action
  async removeNotificationDelayed(payload: {
    notification: AppNotification;
    timeout: number;
  }) {
    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        this.removeNotification(payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  }
}
