import { VuexModule, Module, Mutation, Action, config } from "vuex-module-decorators";
import {
  Group,
  ProfileDetail,
  ProfileUpdate,
  User,
  UserDetail,
  UserListItem,
  UserUpdate,
} from "@/types/users";
import { userApi } from "@/api/users";
import { assertNotNull, safeResolve } from "@/store/utils";
import { uiStore } from "@/store";
import { Cache, CacheKey, CacheValue } from "@/types/utils";

config.rawError = true;

type UserCache = Cache<User["id"], UserDetail>;

@Module({ name: "user", namespaced: true })
export default class UserModule extends VuexModule {
  groups: Group[] | null = null;
  users: UserListItem[] | null = null;
  profile: ProfileDetail | null = null;
  userCache: UserCache = {};

  @Action
  async updateUser(payload: { id: User["id"]; data: UserUpdate }) {
    const { data, errors } = await safeResolve(
      userApi.updateUser(payload.id, payload.data),
      true,
    );

    if (errors) {
      return errors;
    } else if (data) {
      uiStore.addNotification({ content: data.detail, color: "success" });
    }
  }

  @Mutation
  addUserToCache(payload: { id: CacheKey<UserCache>; data: CacheValue<UserCache> }) {
    this.userCache[payload.id] = payload.data;
  }

  @Action
  async getUser(payload: { id: User["id"] }) {
    const userId = payload.id;
    const userData = this.userCache[userId] ?? null;
    if (userData === null) {
      const data = await safeResolve(userApi.getUser(userId));
      this.addUserToCache({ id: userId, data });
    }
    return assertNotNull(this.userCache[userId]);
  }

  @Action
  async deleteUser(payload: User["id"]) {
    const data = await safeResolve(userApi.deleteUser(payload));
    uiStore.addNotification({ content: data.detail, color: "success" });
  }

  @Mutation
  setUsers(data: UserListItem[] | null) {
    this.users = data;
  }

  @Action
  async listUsers(cache = true) {
    if (this.users === null || !cache) {
      const data = await safeResolve(userApi.listUsers());
      this.setUsers(data);
    }
    return assertNotNull(this.users);
  }

  @Mutation
  setGroups(data: Group[] | null) {
    this.groups = data;
  }

  @Action
  async listGroups(cache = true) {
    if (this.groups === null || !cache) {
      const data = await safeResolve(userApi.listGroups());
      this.setGroups(data);
    }
    return assertNotNull(this.groups);
  }

  @Mutation
  async setProfile(data: ProfileDetail | null) {
    this.profile = data;
  }

  @Action
  async getProfile(cache = true) {
    if (this.profile === null || !cache) {
      const data = await safeResolve(userApi.getProfile());
      this.setProfile(data);
    }
    return assertNotNull(this.profile);
  }

  @Action
  async updateProfile(payload: ProfileUpdate) {
    const { data, errors } = await safeResolve(userApi.updateMe(payload), true);

    if (errors) {
      return errors;
    } else if (data) {
      this.setProfile(data);
      uiStore.addNotification({
        content: "Profil erfolgreich aktualisiert",
        color: "success",
      });
    }
  }

  get hasAdminAccess() {
    return this.profile && this.profile.is_superuser && this.profile.is_active;
  }

  @Action
  clearCache() {
    this.setUsers(null);
    this.setProfile(null);
  }
}
